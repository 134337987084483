<template>
  <div class="container" v-title :data-title="$t('i18n.queryOfProblemParts')">
    <div id="outer-title">{{ $t("i18n.queryOfProblemParts") }}</div>
    <div v-loading="loading">
      <jl-table
        :tableData="tableData"
        @init="init"
        :options="options"
        :columns="columns"
        :operates="operates"
        :total="total"
        :height="$defined.HEIGHT - 360 + 'px'"
      >
      </jl-table>
    </div>
  </div>
</template>
<script>
import { reactive, toRefs, getCurrentInstance } from "vue";
import jlTable from "../../components/table";
export default {
  name: "QueryOfProblemParts",
  components: {
    jlTable,
  },
  setup(props, { emit }) {
    emit("public_header", true);
    const { proxy } = getCurrentInstance();
    const state = reactive({
      formInline: {
        pageNumber: 1,
        pageSize: 10,
      },
      // table数据
      tableData: [],
      options: {
        // table 的参数
        isAdd: false, // 是否启用新增功能
        isEdit: false, // 是否启用编辑功能
        isDelete: false, // 是否启用删除功能
        highlightCurrentRow: false, // 是否支持当前行高亮显示
        multiSelect: false, // 是否支持列表项选中功能
        pageShow: true, // 是否翻页
      },
      columns: [
        // 需要展示的列
        {
          prop: "mountingsName",
          label: "partName",
          align: "center",
          search: true,
          type: "input",
        },
        {
          prop: "elevatorNumber",
          label: "elevatorNo",
          align: "center",
          search: true,
          type: "input",
        },
        {
          prop: "dischargeDate",
          label: "manufactureDate",
          align: "center",
        },
        {
          prop: "replacementDate",
          label: "updateDate",
          align: "center",
        },
        {
          prop: "renewalReason",
          label: "updateReason",
          align: "center",
        },
        {
          prop: "mountingsState",
          label: "status",
          align: "center",
          search: true,
          type: "select",
          props: { label: "label", value: "value" },
          data: [
            { label: "黄色", value: 1 },
            { label: "橙色", value: 2 },
            { label: "红色", value: 3 },
          ],
          formatter: (row) => {
            if (row.mountingsState == 1) {
              return `<span style="color: yellow">黄色</span>`;
            } else if (row.mountingsState == 2) {
              return `<span style="color: orange">橙色</span>`;
            } else if (row.mountingsState == 3) {
              return `<span style="color: red">红色</span>`;
            }
          },
        },
      ],
      operates: {
        // 列操作按钮
        width: 140,
        fixed: "right",
        list: [],
      },
      total: 0,
      loading: true,
      editData: {},
    });

    const init = async (params) => {
      state.loading = true;
      params.isTrouble = true;
      const { data } = await proxy.$api.detection.getQueryPartPage(params);
      state.tableData = data.records;
      state.total = data.total;
      state.loading = false;
    };

    return {
      ...toRefs(state),
      init,
    };
  },
};
</script>
